import { useEffect } from "react";


export default function Mobile(){
    useEffect(()=>{
        window.location.href="/m"
        localStorage.setItem("updatePath",true)
  
    })
    return(
        <></>
    )
}
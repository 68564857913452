import { useEffect } from "react";


export default function Desktop(){
    useEffect(()=>{
        localStorage.setItem("updatePath",true)
        window.location.href="/d"
  
    })
    return(
        <></>
    )
}